<template>
  <page-container class="send-back-container">
    <div class="send-back-title-container">
      <div class="send-back-title">退货</div>
      <div class="send-back-sub-title">退货时请正确填写以下物流信息</div>
    </div>
    <van-cell-group v-if="false">
      <van-cell title="收货地址：" :value="backAddressInfo.address"/>
      <van-cell title="收货人：" :value="backAddressInfo.name"/>
      <van-cell title="收货人电话：" :value="backAddressInfo.phone"/>
      <van-cell title="邮编：" :value="backAddressInfo.postCode"/>
    </van-cell-group>

    <van-form
        @submit="handleSendBack"
        label-width="7.5em"
    >

      <div class="form-card">
        <van-field
            :value="form.expressCode"
            name="street"
            type="textarea"
            rows="1"
            :autosize="true"
            is-link
            readonly
            input-align="right"
            label="退货快递公司"
            placeholder="退货快递公司"
            @click="() => $router.push('/expressList')"
            :rules="[{ required: true, message: '请选择退货快递公司' }]"
        >
          <template #input>
            <span v-if="expressInfo.name">{{ expressInfo.name }}</span>
            <span v-else style="color: #888">点击选择退货快递公司</span>
          </template>
        </van-field>

        <van-field
            v-model.trim="form.expressNum"
            name="expressNum"
            input-align="right"
            label="退货快递单号"
            placeholder="退货快递单号"
            :rules="[{ required: true, message: '请输入退货快递单号' }]"
        />
      </div>

      <div class="submit-container">
        <van-button round block type="primary" native-type="submit">确认退货</van-button>
      </div>
    </van-form>
  </page-container>
</template>

<script>
import { mapState } from 'vuex';
import { getBackAddress, sendOrderBack } from '@/api/normalOrder';
import { Dialog } from 'vant';

export default {
  name: 'sendBack',
  data() {
    return {
      backAddressInfo: {},
      form: {
        orderReturnId: '',
        expressCode: '', // 快递公司
        expressNum: '', // 退货快递单号
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.sendBack.refreshing,
      expressInfo: state => state.sendBack.expressInfo,
    })
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('sendBack/setRefreshing', false);
      this.$store.commit('sendBack/setExpressInfo', {});
      let orderReturnId = this.$route.query.orderReturnId;
      this.form.orderReturnId = orderReturnId;
      this.form.expressNum=""

      getBackAddress({
        id: orderReturnId,
      }).then(res => {
        this.backAddressInfo = res || {};
      });
    }, { immediate: true });

    this.$watch('expressInfo', () => {
      this.form.expressCode = this.expressInfo.id;
    }, { immediate: true, deep: true });
  },
  methods: {
    handleSendBack() {
      Dialog.confirm({
        title: `是否确认退货？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            sendOrderBack({ ...this.form }).then(res => {
              if (res === true) {
                done();
                this.$store.commit('afterSaleList/setRefreshing', true);
                this.$store.commit('sendBack/setRefreshing', true);
                this.$router.back();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="less" scoped>
.send-back-container {
  .send-back-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 15px;
    height: 100px;
    color: #fff;
    background: linear-gradient(to right, #ff9c57, #ff7c1b);

    .send-back-title {
      font-size: 18px;
      font-weight: bolder;
    }

    .send-back-sub-title {
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .form-card {
    box-sizing: border-box;
    margin: 20px 10px 10px 10px;
    padding: 5px 0;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }


  .submit-container {
    margin: 20px 10px 10px 10px;
  }
}
</style>
